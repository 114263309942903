(function () {
  'use strict';

  angular
  .module('neo.home.resultats')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.resultats', {
      url: '/resultats/{idPartit}',
     
          templateUrl: 'home/resultats/resultats.tpl.html',
          controller: 'resultatsCtrl',
          controllerAs: 'vm'
        ,
      resolve:{
       
        resultat: function ( resultats,$stateParams){
          return resultats.query({idPartit :  $stateParams.idPartit}).$promise;
    },
    valoracionstecnicspartitslocal:function ( valoracionstecnicslocal,$stateParams){
          return valoracionstecnicslocal.query({idPartit :  $stateParams.idPartit}).$promise;
    },
    valoracionstecnicspartitsvisitant:function ( valoracionstecnicsvisitant,$stateParams){
          return valoracionstecnicsvisitant.query({idPartit :  $stateParams.idPartit}).$promise;
    },
    
    sancions:function ( sancions,$stateParams){
          return sancions.query().$promise;
    }
      }
    })
            ; }
}());

